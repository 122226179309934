import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotifyserviceService {

  constructor(private toaster : ToastrService) { }
  success(message){
    return this.toaster.success(message, "Success",{
      timeOut: 2000,
      closeButton: true,
      enableHtml: true,
      toastClass: "alert alert-success alert-with-icon",
      positionClass: 'toast-' + 'top' + '-' +  'right'
    });
  }
  error(message){
    return this.toaster.error(message, "Error",{
            timeOut: 2000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: 'toast-top-right'
          });
  }
  warning(message){
    this.toaster.warning(message, 'Warning', {
      timeOut: 2000,
      closeButton: true,
      enableHtml: true,
      toastClass: "alert alert-warning alert-with-icon",
      positionClass: 'toast-top-right'
    });
  }
  info(message){
    this.toaster.info(message, 'Info', {
      timeOut: 3000,
      closeButton: true,
      enableHtml: true,
      toastClass: "alert alert-info alert-with-icon",
      positionClass: 'toast-top-right'
    });
  }
}
