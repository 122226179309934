import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private route:Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //  var status = localStorage.getItem('loginstatus')
  //     if(status == 'true'){
  //       // this.route.navigateByUrl('login')
  //       return true;
  //   } else{
  //     this.route.navigate(['/login']);
  //     localStorage.clear();
  //     return false
  // }
  const currentUser = localStorage.getItem('loginstatus');
  if(currentUser){
      return true
    }
    else{
      this.route.navigate(['/login']);
      localStorage.clear();
      return false
  }

    
  }
  
}
