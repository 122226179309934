import { Component, OnInit } from '@angular/core';
import { ApiservicesService } from '../services/apiservices.service';
import { NotifyserviceService } from '../services/notifyservice.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  constructor(private connectservice : ApiservicesService,
              private notifyService : NotifyserviceService,
              private route:Router) { }
  signupobj:any={}
  ngOnInit(): void {
  }
  submit(){
    this.connectservice.orderbookPostRequest('api/user/signup',this.signupobj, JSON.parse(localStorage.getItem('bwilatoken'))).subscribe((res: any) => {      
      this.route.navigateByUrl('/login')
      this.notifyService.success(res.message)
    },(err)=>{
      console.log(err.error.message);
      this.notifyService.error(err.error.message)
    })
  }
}
