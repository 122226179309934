import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from "@angular/router"
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    type: string;
}
export const ROUTES: RouteInfo[] = [
    // { path: '/dashboard', title: 'Dashboard',  icon: 'design_app', class: '' },
    // { path: '/notifications', title: 'Notifications',  icon:'ui-1_bell-53', class: '' },

    // { path: '/user-profile', title: 'User Profile',  icon:'users_single-02', class: '' },
    // { path: '/table-list', title: 'Table List',  icon:'design_bullet-list-67', class: '' },
    { path: '/admin/arbitrage-bot', title: 'Arbitrage Bot', icon: 'ui-2_settings-90', class: '', type: 'arbitrage' },
    { path: '/admin/orderbook', title: 'Order Book', icon: 'design_bullet-list-67', class: '', type: 'arbitrage' },
    { path: '/admin/triarblive', title: 'Triarblive', icon: 'design_bullet-list-67', class: '', type: 'arbitrage' },
    { path: '/admin/exchange', title: 'Exchange', icon: 'loader_refresh', class: '', type: 'arbitrage' },
    { path: '/admin/pair', title: 'Pair', icon: 'business_money-coins', class: '', type: 'arbitrage' },
    { path: '/admin/profit-log', title: 'Bot Profit', icon: 'design_app', class: '', type: 'arbitrage' },
    { path: '/admin/logs', title: 'Logs', icon: 'files_paper', class: '', type: 'arbitrage' },
    { path: '/admin/reverse-order', title: 'Reverse Order', icon: 'arrows-1_minimal-left', class: '', type: 'arbitrage' },
    { path: '/admin/wallets', title: 'Wallets', icon: 'business_money-coins', class: '', type: 'arbitrage' },
    { path: '/admin/transaction-inventory', title: 'Transaction Inventory', icon: 'business_money-coins', class: '', type: 'arbitrage' },
    { path: '/admin/authentication', title: 'Authentication', icon: 'users_single-02', class: '', type: 'arbitrage' },
    { path: '/admin/sor', title: 'SOR', icon: 'ui-2_settings-90', class: '', type: 'sor' },
    { path: '/admin/sor-tradehistory', title: 'SOR Trade History', icon: 'business_money-coins', class: '', type: 'sor' },
    { path: '/admin/sor-balance', title: 'SOR Balance', icon: 'business_money-coins', class: '', type: 'sor' },
    { path: '/admin/sor-exchange', title: 'SOR Exchange', icon: 'loader_refresh', class: '', type: 'sor' },
    { path: '/admin/sor-pair', title: 'SOR Pair', icon: 'design_bullet-list-67', class: '', type: 'sor' },
    { path: '/admin/triarbitrage', title: 'Triarbitrage', icon: 'ui-2_settings-90', class: '', type: 'sor' }
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    menuItems: any[];
    model: any = {};
    currentMenu = 'Arbitrage';
    arbitrageMenu: any[];
    sorMenu: any[];
    role = localStorage.getItem('role');

    constructor(private router: Router) {}
    ngOnInit() {

        this.arbitrageMenu = ROUTES.filter(x =>
            x.type == 'arbitrage');

        this.sorMenu = ROUTES.filter(x =>
            x.type == 'sor');

        if (localStorage.getItem("activeMenu")) {
            this.currentMenu = localStorage.getItem("activeMenu");
        }

        if (this.currentMenu == "Arbitrage") {
            if (this.role == "ADMIN") {
                this.menuItems = this.arbitrageMenu;
                this.model.menu = false;
            } else {
                this.menuItems = [{ path: '/admin/orderbook', title: 'Order Book', icon: 'design_bullet-list-67', class: '', type: 'arbitrage' }]
                this.model.menu = false;
            }

        } else {
            this.menuItems = this.sorMenu;
            this.model.menu = true;
        }

        //this.menuItems = ROUTES.filter(menuItem => menuItem);


    }
    isMobileMenu() {
        if (window.innerWidth > 991) {
            return false;
        }
        return true;
    };
    logincheck() {
        if (!localStorage.getItem('loginstatus') == true) {
            this.router.navigateByUrl('/login')
        }
    }
    changeMenu(event) {
        console.log("event.target===", event.target.value);
        console.log("this.model.", this.model.menu);
        if (this.model.menu) {
            console.log("call sor===")
            localStorage.setItem("activeMenu", "SOR");
            this.menuItems = this.sorMenu;
            this.router.navigateByUrl('/admin/sor');
        } else {
            localStorage.setItem("activeMenu", "Arbitrage");

            if (this.role !== "ADMIN") {
                this.menuItems = [{ path: '/admin/orderbook', title: 'Order Book', icon: 'design_bullet-list-67', class: '', type: 'arbitrage' }]
            } else {
                this.menuItems = this.arbitrageMenu;
            }

            this.router.navigateByUrl('/admin/orderbook');
        }

    }
}