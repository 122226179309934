import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifyserviceService } from '../services/notifyservice.service';
import { ApiservicesService } from '../services/apiservices.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private connectservice : ApiservicesService,
              private notifyService: NotifyserviceService,
              private route:Router) { }
  resetobj:any = {}
  ngOnInit(): void {
  }
  reset(){
    this.connectservice.orderbookPostRequest('api/user/forgotpassword',this.resetobj, JSON.parse(localStorage.getItem('bwilatoken'))).subscribe((res: any) => {   
      this.notifyService.success(res.message)
      // this.route.navigateByUrl('/login')
    },(err)=>{
      this.notifyService.error(err.error.message)
    })
  }
}
