import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifyserviceService } from '../services/notifyservice.service';
import { ApiservicesService } from '../services/apiservices.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    constructor(private route: Router,
        private connectservice: ApiservicesService,
        private notifyService: NotifyserviceService) {}
    loginobj: any = {}
    activeMenu: any = '';
    ngOnInit(): void {
        this.activeMenu = localStorage.getItem("activeMenu");
        if (localStorage.getItem('loginstatus')) {
            if (this.activeMenu == "SOR") {
                this.route.navigateByUrl('/admin/sor')
            } else {
                this.route.navigateByUrl('/admin/orderbook')
            }
        }
    }

    login() {
        this.connectservice.orderbookPostRequest('api/user/login', this.loginobj, JSON.parse(localStorage.getItem('bwilatoken'))).subscribe((res: any) => {
            var token = res.token;
            var role = res.role;

            localStorage.setItem('loginstatus', token)
            localStorage.setItem('role', role)
            if(role == "SUBADMIN") localStorage.setItem('activeMenu', "Arbitrage")

            this.notifyService.success(res.message)
            if (role != "SUBADMIN" && this.activeMenu == "SOR") {
                this.route.navigateByUrl('/admin/sor')
            } else {

                this.route.navigateByUrl('/admin/orderbook')
            }
        }, (err) => {
            this.notifyService.error(err.error.message)
        })
        // if(this.loginobj.email == 'admin@eclipton.com' && this.loginobj.password == 'Test@123'){
        //   this.route.navigateByUrl('admin/orderbook')
        //   var logstat:any = true;
        //   localStorage.setItem('loginstatus',logstat)
        //   this.notifyService.success('Success fully login')
        // } else {
        //   if (this.loginobj.username !== 'admin@eclipton.com' || this.loginobj.password !== 'Test@123') {
        //         this.notifyService.error('User name or password is Invalid ')
        //   }
        // }
    }

    clearError(obj) {
        // switch (obj) {
        //   case 'username':
        //     if (this.loginobj.username == '') {
        //       // this.loginobj.username = 0
        //     }
        //     break;
        //     case 'password':
        //       if (this.loginobj.password = '') {
        //         // this.loginobj.password = 0
        //       }
        //       break;
        //   }
    }

}